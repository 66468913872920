import { FC, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Expand from 'components/Icons/Expand';
import Minus from 'components/Icons/Minus';
import FullscreenOverlay from 'components/Others/FullscreenOverlay';
import HeaderBookingButton from '../HeaderBookingButton';
import { UpdateRegion } from '../HeaderNavigation';
import {
  HeaderLoggedUser,
  HeaderNavigationCategory,
  MenuActionClickHandler
} from '../Shared/useHeaderHelpers';
import MobileSidebarUser from './MobileSidebarUser';


interface MobileSidebarOverlayProps {
  categoryList: HeaderNavigationCategory[];
  visible: boolean;
  // eslint-disable-next-line no-unused-vars
  updateVisibility: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  menuActionClickHandler: MenuActionClickHandler;
  loggedUser?: HeaderLoggedUser;
  updateRegion: UpdateRegion;
}

const MobileSidebarOverlay: FC<MobileSidebarOverlayProps> = ({
  visible,
  updateVisibility,
  categoryList,
  menuActionClickHandler,
  loggedUser,
  updateRegion
}) => {
  return (
    <FullscreenOverlay
      isVisible={visible}
      onToggleVisibility={updateVisibility}
      direction="left"
      className="h-full max-w-[278px] !p-0"
      contentWrapperClassName="h-full !px-0 !py-0">
      <div className="flex h-full flex-col justify-between">
        <div className="mb-[30px] flex-auto px-[16px] pt-[16px]">
          <MobileSidebarCategoryList updateVisibility={updateVisibility} categoryList={categoryList} />
          <Link
            prefetch={false}
            href="/stores"
            className="mt-[14px] block text-[18px] font-medium text-brand-black100"
            onClick={() => updateVisibility(false)}>

            Stores

          </Link>
          <HeaderBookingButton className="mt-[14px] block text-[18px] hover:text-brand-blue" />
        </div>
        <MobileSidebarUser
          loggedUser={loggedUser}
          onMenuActionClicked={menuActionClickHandler}
          updateRegion={updateRegion}
        />
      </div>
    </FullscreenOverlay>
  );
};

interface MobileSidebarCategoryListProps {
  categoryList: HeaderNavigationCategory[];
  // eslint-disable-next-line no-unused-vars
  updateVisibility: (value: boolean) => void;
}
const MobileSidebarCategoryList: FC<MobileSidebarCategoryListProps> = ({
  categoryList,
  updateVisibility
}) => {
  const [expandedCategory, setExpandedCategory] = useState('');

  const toggleCategory = (category: string) => {
    if (expandedCategory === category) return setExpandedCategory('');

    setExpandedCategory(category);
  };

  return (
    <>
      {categoryList.map((category) => (
        <MobileSidebarCategory
          key={category.name}
          expanded={expandedCategory === category.name}
          category={category}
          toggle={toggleCategory}
          updateVisibility={updateVisibility}
        />
      ))}
    </>
  );
};

const MobileSidebarCategory = ({
  expanded,
  toggle,
  category,
  updateVisibility
}: {
  expanded: boolean;
  // eslint-disable-next-line no-unused-vars
  toggle?: (categoryName: string) => void;
  category: HeaderNavigationCategory;
  // eslint-disable-next-line no-unused-vars
  updateVisibility: (value: boolean) => void;

}) => {
  const router = useRouter();

  return (
    <div className="mt-[14px] first:mt-0">
      <div
        className="flex items-center justify-between font-medium text-brand-black100"
        onClick={() => toggle?.(category.name)}>
        <span className="text-[18px]">{category.name}</span>
        {expanded ? (
          <Minus fill="currentColor" />
        ) : (
          <Expand fill="currentColor" />
        )}
      </div>
      {expanded && (
        <div className="px-[10px] pt-[14px]">
          {category.items?.map((item) => {
            return item.name === "Gift Cards" ?
              (
                <a
                  href={'/' + router.locale + item.path}
                  onClick={() => updateVisibility(false)}
                  className={'mb-[14px] block text-[16px] text-brand-grey300 last:mb-0'}>
                  {item.name}
                </a>
              ) :
              (
                (<Link
                  prefetch={false}
                  key={item.name}
                  href={item.path}
                  onClick={() => updateVisibility(false)}
                  className="mb-[14px] block text-[16px] text-brand-grey300 last:mb-0">

                  {item.name}

                </Link>)
              );
          })}

          {category.sections?.map((item) => (
            item.items.map((n: any) => (n.name != "View All Contacts" && <Link
              prefetch={false}
              key={n.name}
              href={n.path}
              onClick={() => updateVisibility(false)}
              className="mb-[14px] block text-[16px] text-brand-grey300 last:mb-0">

              {n.name}

            </Link>))
          ))
          }
          {(category.name == 'Contact Lenses' && router.locale === 'ca') && <Link
            prefetch={false}
            href={'/shop/contact-lenses?bundle=true'}
            onClick={() => updateVisibility(false)}
            className="mb-[14px] block text-[16px] text-brand-grey300 last:mb-0">

            Discounted Bundle Options

          </Link>}
          {category.name == 'Contact Lenses' && <Link
            prefetch={false}
            href={'/shop/contact-lenses'}
            onClick={() => updateVisibility(false)}
            className="mb-[14px] block text-[16px] text-brand-grey300 last:mb-0">

            View All Contacts

          </Link>}
          {category.name == "Accessories" && <Link
            prefetch={false}
            href={'/shop/accessories'}
            onClick={() => updateVisibility(false)}
            className="mb-[14px] block text-[16px] text-brand-grey300 last:mb-0">

            View All Accessories

          </Link>}
        </div>
      )}
    </div>
  );
};

export default MobileSidebarOverlay;
